import rose from '../Palettes/rose';

const colors = {
  primary: rose[600],
  primaryFallback: rose[600],
  blackHigh: 'rgba(0, 0, 0, 0.87)',
  blackMedium: 'rgba(0, 0, 0, 0.6)',
  blackInactive: 'rgba(0, 0, 0, 0.54)',
  blackDisabled: 'rgba(0, 0, 0, 0.38)',
  black: '#000',
  secondary: rose[600],
  primaryText: rose[700],
  greyDark: '#58595b',
  greyLight: '#dbdbdb',
  whiteLight: 'rgba(255, 255, 255, 0.7)',
  white: '#fff',
  blue: '#1976d2',
};

export default colors;
